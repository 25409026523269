'use strict'

const _ = require('lodash')
const wixCodeRemoteModelService = require('../utils/wixCodeRemoteModelService')

function build(dataAPI, isMockSiteData, contextId, options) {
    const navigationData = dataAPI.RGI.fetchNavigationData(isMockSiteData, _.get(options, 'pageInfo'))
    const sessionInfo = dataAPI.RGI.fetchSessionInfo()
    const siteMemberData = dataAPI.RGI.fetchSiteMemberData()
    const multilingualInfo = dataAPI.RGI.fetchMultilingualInfo()
    const appsData = dataAPI.RGI.fetchAppsData()
    const deviceType = dataAPI.RGI.fetchDeviceType()
    const isApplicationStudio = dataAPI.RGI.fetchIsApplicationStudio()
    return wixCodeRemoteModelService.generateRemoteGlobalsInterface(navigationData, sessionInfo, siteMemberData, multilingualInfo, appsData, isApplicationStudio, deviceType, _.get(options, 'lightboxContext'), contextId)
}

module.exports = {
    build
}
