'use strict'

function setBootstrapParametersMessage(parameters) {
    return {
        intent: 'WIX_CODE',
        type: 'wix_code_iframe_set_bootstrap_parameters',
        parameters
    }
}

/**
 * @param widgetsIdAndType {{id: string, type: string}[]}
 * @param routersMap
 * @param rootIds
 * @returns {{type: string, widgets: *, rootIds: *, routersMap: (*|{}), popupContexts: (*|{})}}
 */
function loadUserCodesMessage(widgetsIdAndType, rootIds) {
    return {
        type: 'load_user_code',
        widgets: widgetsIdAndType,
        rootIds
    }
}


/**
 * @param widgetsIdAndType {{id: string, type: string}[]}
 * @param rootIds
 * @returns {{type: string, widgets: *, rootIds: *}}
 */
function updateWixCodeModelDataAfterLoginMessage(widgetsIdAndType, rootIds) {
    return {
        type: 'update_wix_code_model_data_after_login',
        rootIds,
        widgets: widgetsIdAndType
    }
}

/**
 * @param widgetsIdAndType {{id: string, type: string}[]}
 * @param rootIds
 * @param routersMap
 * @returns {{handlerId: *, type: string, widgets: *}}
 */
function loadWidgetsMessage(widgetsIdAndType, routersMap, rootIds, rgisByRootId) {
    return {
        type: 'load_widgets',
        sdkParameters: {
            referrer: typeof window === 'undefined' || !window.document ? '' : window.document.referrer
        },
        widgets: widgetsIdAndType,
        rootIds,
        routersMap: routersMap || {},
        rgisByRootId
    }
}

/**
 *
 * @param controllers
 * @returns {{handlerId: string, type: string, widgets: *}}
 */
function initWidgetsMessage(contexts) {
    return {
        type: 'init_widgets',
        contexts
    }
}

function startWidgetsMessage(contextIdToModelMap) {
    return {
        type: 'start_widgets',
        contexts: contextIdToModelMap
    }
}

function triggerOnRenderMessage(contextId) {
    return {
        intent: 'WIX_CODE',
        type: 'trigger_onRender',
        contextId
    }
}

function triggerUserFunctionMessage(contextId, params, event) {
    return {
        intent: 'WIX_CODE',
        type: 'wix_code_run_user_function',
        contextId,
        callbackId: params.callbackId,
        compId: params.compId,
        event
    }
}


/**
 *
 * @param widgetIds
 * @returns {{type: string, widgetIds: string[]}}
 */
function stopWidgetsMessage(widgetIds) {
    return {
        type: 'stop_widgets',
        widgetIds
    }
}

/**
 *
 * @param contextId
 * @param componentUpdates
 * @returns {{type: string, widgetInstanceId: string, updates: *}}
 */
function updateWidgetMessage(contextId, componentUpdates) {
    return {
        type: 'update',
        contextId,
        updates: componentUpdates
    }
}

/**
 *
 * @param contextId
 * @param siteMemberUpdates
 * @returns {{type: string, contextId: string, updates: *}}
 */
function updateSiteMemberData(contextId, siteMemberUpdates) {
    return {
        contextId,
        type: 'update_site_member',
        updates: siteMemberUpdates
    }
}

/**
 *
 * @param contextId
 * @param multilingualInfoUpdates
 * @returns {{type: string, contextId: string, updates: *}}
 */
function updateMultilingualInfo(contextId, multilingualInfoUpdates) {
    return {
        contextId,
        type: 'update_multilingual_info',
        updates: multilingualInfoUpdates
    }
}

/**
 *
 * @param contextId
 * @param oldProps
 * @param newProps
 * @returns {{type: string, contextId: string, oldProps: *, newProps:*}}
 */
function triggerAppStudioWidgetOnPropsChanged(contextId, oldProps, newProps) {
    return {
        contextId,
        type: 'trigger_app_studio_widget_on_props_changed',
        oldProps,
        newProps
    }
}

/**
 *
 * @param contextId
 * @param appsData
 * @returns {{type: string, contextId: string, updates: *}}
 */
function updateAppsData(contextId, appsData) {
    return {
        contextId,
        type: 'update_apps_data',
        updates: appsData
    }
}

/**
 *
 * @param contextId
 * @param updateSessionInfo
 * @returns {{type: string, contextId: string, updates: *}}
 */
function updateSessionInfo(contextId, sessionInfoUpdates) {
    return {
        contextId,
        type: 'update_session_info',
        updates: sessionInfoUpdates
    }
}

function updateaNavigation(contextId, navigation) {
    return {
        contextId,
        type: 'update_navigation',
        updates: navigation
    }
}

function stopMessage(contextId) {
    return {
        contextId,
        type: 'stop'
    }
}

function isStopMessage(message) {
    return message.type === 'stop'
}

/**
 * @param contextId - the contextId of the worker
 * @param appDefId - the appDefinitionId of the application
 * @param data - the data of the message
 * @returns {{type: string, contextId: string, appDefId: string, data: object}}
 */
function invokeWorkerSubscribersMessage(contextId, appDefId, data) {
    return {
        type: 'invoke_worker_subscribers',
        contextId,
        appDefId,
        data
    }
}

module.exports = {
    setBootstrapParametersMessage,
    loadWidgetsMessage,
    loadUserCodesMessage,
    initWidgetsMessage,
    startWidgetsMessage,
    stopWidgetsMessage,
    updateWidgetMessage,
    updateSiteMemberData,
    updateMultilingualInfo,
    updateSessionInfo,
    updateaNavigation,
    triggerOnRenderMessage,
    triggerUserFunctionMessage,
    updateWixCodeModelDataAfterLoginMessage,
    updateAppsData,
    stopMessage,
    isStopMessage,
    triggerAppStudioWidgetOnPropsChanged,
    invokeWorkerSubscribersMessage
}
