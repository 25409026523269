'use strict'

const coreUtilsLib = require('santa-core-utils')
const wixDomSanitizer = require('wix-dom-sanitizer')

const {svgUtils, guidUtils, requestsUtil} = coreUtilsLib
const {SOURCE_TYPES, EMPTY_SVG_ID, EMPTY_SHAPE, getSourceType} = svgUtils
const {nameUUIDFromString: toGUID} = guidUtils
const fetchOtions = {
    method: 'GET',
    headers: {'content-type': 'text/plain'},
    mode: 'cors'
}

/**
 * Pass svg string through HTML Sanitizer
 * @param svgString
 * @returns {*}
 */
function parseAndCleanSvg(svgString) {
    const {svg, error} = wixDomSanitizer.sanitizeSVG(svgString)

    if (!svg) {
        console.warn(error) //eslint-disable-line no-console
        return EMPTY_SHAPE
    }
    return svg
}

/**
 * Handle external svg url
 * If the source of the exrernal is already saved in cache, return it,
 * else return the src and resolve svgId async via ajax call to the external source
 * @param {SiteAPI} siteAPI
 * @param {string} svgSrc
 * @param {string} compId
 * @returns {{svgId?: string, svgSrc: string}}
 */
function handleExternalSvg(siteAPI, svgSrc, compId) {
    const svgId = toGUID(svgSrc)
    const pointers = siteAPI.getPointers()
    const displayedDAL = siteAPI.getDisplayedDAL()
    const runtimeDAL = siteAPI.getRuntimeDal()
    const svgStorePointer = pointers.svgShapes.getSvgShapePointer(svgId)
    const svgStoreData = displayedDAL.get(svgStorePointer)

    const error = () => {
        //TODO: log error
        const {svgSrc: lastSetSource} = runtimeDAL.getCompData(compId)
        if (svgSrc === lastSetSource) {
            runtimeDAL.setCompData(compId, {svgId: EMPTY_SVG_ID, svgSrc})
        }
    }

    //Always save SVGs to store but set to component only the last called svg
    const success = response => response.text().then(svgString => {
        displayedDAL.set(svgStorePointer, siteAPI.getSvgStringToStoreData(parseAndCleanSvg(svgString)))
        const {svgSrc: lastSetSource} = runtimeDAL.getCompData(compId)
        if (svgSrc === lastSetSource) {
            runtimeDAL.setCompData(compId, {svgId, svgSrc})
        }
    })

    if (!svgStoreData) {
        requestsUtil.fetch(svgSrc, fetchOtions)
            .then(success)
            .catch(error)
        return {svgSrc}
    }

    return {svgId, svgSrc}
}

/**
 * Handle inline svg source
 * if in cache, return svgId, else, parse content, create a new id and save to store
 * @param {SiteAPI} siteAPI
 * @param {string} svgSrc
 * @returns {{svgId: string, svgSrc: string}}
 */
function handleInlineSvg(siteAPI, svgSrc) {
    const svgId = toGUID(svgSrc)
    const pointers = siteAPI.getPointers()
    const displayedDAL = siteAPI.getDisplayedDAL()
    const svgStorePointer = pointers.svgShapes.getSvgShapePointer(svgId)
    const svgStoreData = displayedDAL.get(svgStorePointer)

    if (!svgStoreData) {
        displayedDAL.set(svgStorePointer, siteAPI.getSvgStringToStoreData(parseAndCleanSvg(svgSrc)))
    }

    return {svgId, svgSrc}
}

/**
 *
 * @param {{svgSrc?: string}}data
 * @param {SiteAPI} siteAPI
 * @param {object} props
 * @returns {{}|{svgId: string, svgSrc: string}}
 */
function resolve(data, siteAPI, {compId}) {
    if (data.type !== 'VectorImage' || !data.hasOwnProperty('svgSrc')) {
        return data
    }
    const {svgSrc} = data
    const sourceType = getSourceType(svgSrc)
    switch (sourceType) {
        case SOURCE_TYPES.WIX_MEDIA_ID:
            return {svgId: svgSrc, svgSrc}
        case SOURCE_TYPES.EMPTY:
            return {svgId: EMPTY_SVG_ID, svgSrc}
        case SOURCE_TYPES.URL:
            return handleExternalSvg(siteAPI, svgSrc, compId)
        case SOURCE_TYPES.INLINE:
            return handleInlineSvg(siteAPI, svgSrc)
    }
    return data
}

module.exports = {
    resolve
}
