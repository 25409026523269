'use strict'

const _ = require('lodash')

const WIDGET_TYPES = {
    PAGE: 'Page',
    POPUP: 'Popup',
    UNKNOWN: 'Unknown'
}

function getApi(siteData) {
    return {
        RMI: {
            fetchData: siteData.getDataByQuery.bind(siteData),
            fetchSiteStructure: siteData.getSiteStructureForRmi.bind(siteData),
            fetchPageData: siteData.getPageDataForRmi.bind(siteData),
            fetchWidgetData: siteData.getAppStudioWidgetProperties.bind(siteData)
        },
        RGI: {
            fetchNavigationData: siteData.getNavigationDataForRgi.bind(siteData),
            fetchSessionInfo: siteData.getSessionInfoForRgi.bind(siteData),
            fetchDeviceType: siteData.getDeviceTypeForRgi.bind(siteData),
            fetchSiteMemberData: siteData.getSMbySiteExtensionInstanceForRgi.bind(siteData),
            fetchMultilingualInfo: siteData.getMultilingualInfoForRgi.bind(siteData),
            fetchAppsData: siteData.getAppsDataForRgi.bind(siteData),
            fetchIsApplicationStudio: siteData.isApplicationStudio.bind(siteData)
        }
    }
}

const validTypes = ['Page', 'AppPage']

function getWidgetType(widgetDataItem) {
    if (!_.includes(validTypes, _.get(widgetDataItem, 'type'))) {
        return WIDGET_TYPES.UNKNOWN
    }

    return _.get(widgetDataItem, 'isPopup') ? WIDGET_TYPES.POPUP : WIDGET_TYPES.PAGE
}

module.exports = {
    getApi,
    getWidgetType,
    WIDGET_TYPES
}
