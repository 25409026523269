'use strict'

const _ = require('lodash')
const coreUtilsLib = require('santa-core-utils')
const wixCodeRemoteModelService = require('../utils/wixCodeRemoteModelService')
const modelBuilderDataHelper = require('./modelBuilderDataHelper')

const siteConstants = coreUtilsLib.siteConstants

function generateRMIs(runtimeDal, contextIdToCompIds, onModelUpdateCallback, fetchSiteStructure, fetchPageData, fetchWidgetData, getCompReactClass) {
    return _.mapValues(contextIdToCompIds, (contextCompIds, contextId) => wixCodeRemoteModelService.generateRemoteModelInterface(runtimeDal, contextCompIds, fetchSiteStructure(), fetchPageData(), fetchWidgetData(contextId), onModelUpdateCallback, contextId, getCompReactClass))
}

function getRootComponentIds(rootId, componentsFetcher) {
    const compIdsToExclude = [siteConstants.PAGES_CONTAINER_ID, siteConstants.SITE_PAGES_ID]
    const pageComponents = componentsFetcher(rootId, rootId)
    return _(pageComponents)
        .omit([siteConstants.SITE_STRUCTURE_ID])
        .keys()
        .difference(compIdsToExclude)
        .value()
}

function isPageContext(rootId, fetchData) {
    const widgetData = fetchData(rootId, rootId)
    const widgetType = modelBuilderDataHelper.getWidgetType(widgetData)
    return widgetType === modelBuilderDataHelper.WIDGET_TYPES.PAGE
}

function collectComponents(rootIds, componentsFetcher, fetchData) {
    const masterPageId = coreUtilsLib.siteConstants.MASTER_PAGE_ID
    const masterPageComponents = getRootComponentIds(masterPageId, componentsFetcher)
    const appsRootIds = _.without(rootIds, masterPageId)

    return _.transform(appsRootIds, (acc, rootId) => {
        acc[rootId] = getRootComponentIds(rootId, componentsFetcher)
        if (isPageContext(rootId, fetchData)) {
            acc[rootId] = acc[rootId].concat(masterPageComponents)
        }
    }, {})
}

function buildModel(runtimeDal, dataAPI, contextIds, onModelUpdateCallback, componentsFetcher, getCompReactClass, performanceLogger) {
    performanceLogger.mark('build RMI model start')
    const contextComponents = collectComponents(contextIds, componentsFetcher, dataAPI.RMI.fetchData)
    const result = generateRMIs(runtimeDal, contextComponents, onModelUpdateCallback, dataAPI.RMI.fetchSiteStructure, dataAPI.RMI.fetchPageData, dataAPI.RMI.fetchWidgetData, getCompReactClass)
    performanceLogger.mark('build RMI model end')
    performanceLogger.measure('build RMI model', 'build RMI model start', 'build RMI model end')

    return result
}

function attachModel(models, contextIds, onModelUpdateCallback) {
    _.forEach(contextIds, context => {
        models[context]._onUpdateCallback = onModelUpdateCallback
    })
    return models
}

module.exports = {
    build: buildModel,
    attach: attachModel
}
